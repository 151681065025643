/* Dialog functionality */

.cdk-overlay-container {
  z-index: 100000000;
}

.e-dlg-container {
  z-index: 1000 !important;
}

.rcg-fullscreen-cdk-overlay-pane {
  width: 100% !important;
  max-width: 100% !important;

  height: 100% !important;
  max-height: 100% !important;

  transform: none !important;
}

.rcg-minimized-cdk-overlay-pane {
  display: none;
}

.rcg-dialog-recenter-icon:not(.rcg-dialog-recenter-icon-show) {
  display: none !important;
}

.rcg-fullscreen-cdk-overlay-pane .rcg-dialog-recenter-icon {
  display: none !important;
}

.cdk-overlay-dark-backdrop {
  display: none;
}

.cdk-overlay-backdrop {
  z-index: 99999;
}

.cdk-overlay-pane {
  .rcg-dialog-minimized-icon,
  .rcg-dialog-minimized-icon-tooltip {
    display: none;
  }
}

.cdk-overlay-pane:not(.rcg-fullscreen-cdk-overlay-pane) .cdk-drag-handle {
  cursor: move;
}
