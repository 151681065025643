@mixin phone-lib($theme) {
  // Determine basic palletes for all main areas
  $primary-pallete: map-get($theme, primary);
  $accent-pallete: map-get($theme, accent);
  $warning-pallete: map-get($theme, warn);

  // Set theme specific colors
  $m-primary-color: map-get($primary-pallete, 500);
  $m-primary-color-contrast: map-get($primary-pallete, default-contrast);
  $m-primary-color-50: map-get($primary-pallete, 50);
  $m-primary-color-100: map-get($primary-pallete, 100);
  $m-primary-color-400: map-get($primary-pallete, 400);
  $m-primary-color-700: map-get($primary-pallete, 700);
  $m-primary-color-800: map-get($primary-pallete, 800);
  $accent-color-100: map-get($accent-pallete, 100);
  $m-accent-color-500: map-get($accent-pallete, 500);
  $m-accent-color-800: map-get($accent-pallete, 800);
  $m-accent-color-contrast: map-get($accent-pallete, default-contrast);

  $m-warn-color-100: map-get($warning-pallete, 100);
  $m-warn-color-200: map-get($warning-pallete, 200);
  $m-warn-color-500: map-get($warning-pallete, 500);
  $m-warn-color-contrast: map-get($warning-pallete, default-contrast);

  // These colors are same regardles of the theme selected
  $m-qualify-color: #4caf50;
  $m-not-qualify-color: #f44336;
  $m-conditional-qualify-color: #fcd418;

  .sticky-side-content-accent {
    background-color: $m-accent-color-800;
    color: $m-accent-color-contrast;
  }

  .sticky-side-content-primary {
    background-color: $m-primary-color;
    color: $m-primary-color-contrast;
  }

  .sticky-side-content-warn {
    background-color: $m-warn-color-500;
    color: $m-warn-color-contrast;
  }

  .answer-call-button {
    background-color: $m-qualify-color !important;
  }

  .end-call-button {
    background-color: $m-not-qualify-color !important;
  }

  .decline-call-button {
    background-color: $m-not-qualify-color !important;
  }

  .link-call-button {
    background-color: $m-warn-color-200 !important;
  }

  .active-phone-call-animation {
    -webkit-animation: active-call 600ms infinite alternate ease-in-out;
    -moz-animation: active-call 600ms infinite alternate ease-in-out;
    -o-animation: active-call 600ms infinite alternate ease-in-out;
    animation: active-call 600ms infinite alternate ease-in-out;
    border-radius: 16px;
  }

  .phone-call-transfer-action-text {
    color: $m-primary-color;
  }

  @-webkit-keyframes active-call {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 100%;
      color: $m-conditional-qualify-color;
    }
  }

  @-moz-keyframes active-call {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 100%;
      color: $m-conditional-qualify-color;
    }
  }

  @-o-keyframes active-call {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 100%;
      color: $m-conditional-qualify-color;
    }
  }

  @keyframes active-call {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 100%;
      color: $m-conditional-qualify-color;
    }
  }

  .talking-phone-call{
    color: $m-conditional-qualify-color;
  }
}
