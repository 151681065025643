@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

$container-name: rcg-bs-container;

@mixin container-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @container #{$container-name} (min-width: #{$min}) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin make-col-rcg($size: false, $columns: $grid-columns) {
  @if $size {
    flex: $size 0 auto;
  } @else {
    flex: 1 1 0;
    max-width: 100%;
  }
}

@mixin make-col-rcg-auto() {
  flex: 0 0 auto;
  width: auto;
}

@mixin make-col-rcg-offset($size, $columns: $grid-columns) {
  $num: divide($size, $columns);
  margin-left: if($num == 0, 0, percentage($num));
}

@mixin make-container-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include container-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex: 1 0 0;
      }

      .row-cols#{$infix}-auto > * {
        @include make-col-rcg-auto();
      }

      @if $grid-row-columns > 0 {
        @for $i from 1 through $grid-row-columns {
          .row-cols#{$infix}-#{$i} {
            @include row-cols($i);
          }
        }
      }

      .col#{$infix}-auto {
        @include make-col-rcg-auto();
      }

      @if $columns > 0 {
        @for $i from 1 through $columns {
          .col#{$infix}-#{$i} {
            @include make-col-rcg($i, $columns);
          }
        }

        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
          @if not($infix == '' and $i == 0) {
            // Avoid emitting useless .offset-0
            .offset#{$infix}-#{$i} {
              @include make-col-rcg-offset($i, $columns);
            }
          }
        }
      }

      // Gutters
      //
      // Make use of `.g-*`, `.gx-*` or `.gy-*` utilities to change spacing between the columns.
      @each $key, $value in $gutters {
        .g#{$infix}-#{$key},
        .gx#{$infix}-#{$key} {
          --#{$prefix}gutter-x: #{$value};
        }

        .g#{$infix}-#{$key},
        .gy#{$infix}-#{$key} {
          --#{$prefix}gutter-y: #{$value};
        }
      }
    }
  }
}

$rcg-utilities: map-merge(
  $rcg-utilities,
  (
    'overflow': (
      responsive: true,
      property: overflow,
      values: visible hidden scroll auto,
    ),
  )
);

.bootstrap-styled {
  // Loop over each breakpoint
  @each $breakpoint in map-keys($grid-breakpoints) {
    // Generate container query if needed
    @include container-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      // Loop over each utility property
      @each $key, $utility in $rcg-utilities {
        // The utility can be disabled with `false`, thus check if the utility is a map first
        // Only proceed if responsive media queries are enabled or if it's the base media query
        @if type-of($utility) == 'map' and (map-get($utility, responsive) or $infix == '') {
          @include generate-utility($utility, $infix);
        }
      }
    }
  }

  .row {
    @include make-row();

    > * {
      @include make-col-ready();
    }
  }

  @include make-container-grid-columns();
}

.bs-container,
body {
  container-type: inline-size;
  container-name: $container-name;
}
